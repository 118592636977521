/* eslint-disable max-len */
export const terms = {
  Common: {
    unknownError: 'Erreur inconnue',
    disconnect: 'Déconnexion',
    loading: 'Chargement',
    updated: 'Modifié',
    sortBy: 'Trier par',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    back: 'Retour',
    confirm: 'Confirmer',
    delete: 'Supprimer',
    add: 'Ajouter',
    edit: 'Modifier',
    search: 'Rechercher',
    searching: 'Recherche',
    reset: 'Réinitialiser',
    filter: 'Filtrer',
    close: 'Fermer',
    continue: 'Continuer',
    validate: 'Valider',
    new: 'Nouveau',
    RusImport: 'Importer dans RUS',
    weekdays: [
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
      'Dimanche',
    ],
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    httpStatus: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Erreur de traitement de la requête côté serveur.',
      badGateway: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
    },
  },
  Header: {
    nav: {
      '/missions': 'Vue Matrice',
      '/baremes': 'CDC Barêmes',
      '/flux': 'Flux',
    },
  },
  Pages: {
    Home: {
      content: 'Home Page - Click me',
    },
    Missions: {
      mission: 'Mission',
      addMission: 'Ajouter une mission',
      addGroup: 'Ajouter un lot à l’étude',
      switchDisplayGroup: 'Vue des lots à l’étude',
      section: {
        section: 'Tronçon',
        remplissage: 'Remplissage',
        weight: 'Capacité',
        hours: 'Horaire',
        train: 'Train',
        addValue: 'Valeur Cumul',
        restValue: 'Valeur résiduelle',
        overcapacity: 'Surcapacité',
      },
      Modal: {
        CreateMission: {
          title: 'Créer une nouvelle mission',
          label: 'Veuillez nommer la nouvelle mission :',
          createSuccess: 'Mission créée avec succès',
        },
      },

      StudyLot: {
        ToastMessage: {
          createSuccess: 'Lot à l\'étude ajouté avec succès',
          updateSuccess: 'Lot à l\'étude modifié avec succès',
          deleteSuccess: 'Lot à l\'étude supprimé avec succès',
        },

        Modal: {
          CreateLot: {
            title: 'Créer un lot à l’étude',
            createSuccess: 'Lot créé avec succès',
            name: 'Nom du lot',
            weight: 'Masse (Tonne)',
            length: 'Longueur (Mètre)',
            wagons: 'Nombre de wagons',
          },
        },
      },
    },
    Mission: {
      updatedSuccess: 'Mission modifiée avec succès',
      Delete: {
        FirstAlert: {
          title: 'Voulez-vous vraiment supprimer la mission ?',
          info: 'Celle-ci ne pourra pas être récupérée.',
        },
        SecondAlert: {
          title: 'Précaution de suppression de la mission',
          info: 'Des variantes, lots et autres éléments peuvent être associés à cette mission.',
          additionalInfo: 'Veuillez contacter les métiers concernés pour vous assurer de l\'impact de cette suppression.',
          confirmLabel: 'Je confirme avoir bien contacté les personnes concernées par cette mission et m’assurer de l’impact de sa suppression',
        },
        deleteSuccess: 'La mission a bien été supprimée.',
      },
      Import: {
        missionIdImport: (missionId: string) => `Mission ${missionId} en import.`,
        infoNoReimport: 'La mission ne pourra pas être importée de nouveau ultérieurement.',
        loading: 'Import en cours',
        success: 'Mission importée correctement !',
        failure: 'La mission n’a pas été correctement importée.',
      },
      SideMenu: {
        addVariant: 'Ajouter une variante',
        variant: 'Variante',
      },
      Form: {
        Edit: {
          mission: 'Mission',
          title: 'Édition de la mission',
          missionSecurity: 'Mission de sécurité',
          egs: 'EGS / Entité de production',
          outBfr: 'Hors BFR',
          bfrCode: 'Code BFR',
        },
        Variant: {
          defaultVariant: 'Il s\'agit de la variante par défaut',
          circulationDays: 'Jours de circulation',
          successUpdate: 'Variante modifiée avec succès',
          successCreate: 'Variante créée avec succès',
          deleteSuccess: 'Variante supprimée avec succès',
          Modal: {
            deleteText: 'Êtes-vous sûr de vouloir supprimer cette variante ?',
          },
        },
        Section: {
          section: (plural: boolean) => `Tronçon${plural ? 's' : ''}`,
          successUpdate: 'Tronçon modifié avec succès',
          successCreate: 'Tronçon créé avec succès',
          successDelete: 'Tronçon supprimé avec succès',
          hourInput: (type: 'arretArrivee' | 'arretDepart') => `Heure ${type === 'arretArrivee' ? 'd\'arrivée' : 'de départ'}`,
          midnight: (type: 'arretArrivee' | 'arretDepart') => `Bascule minuit ${type === 'arretArrivee' ? 'arrivée' : 'départ'}`,
          stationLocation: (type: 'arretArrivee' | 'arretDepart') => `Gare ${type === 'arretArrivee' ? 'd\'arrivée' : 'de départ'}`,
          time: (type: 'arretArrivee' | 'arretDepart') => `Heure ${type === 'arretArrivee' ? 'd\'arrivée' : 'de départ'}`,
          generalInfo: 'Informations générales',
          capacityWeight: 'Capacité tonnage (tonne)',
          capacityLength: 'Capacité longueur (mètre)',
          direction: 'Sens relève',
          tube: 'Tube',
          name: 'Nom',
          numSillon: 'Sillon',
          Modal: {
            deleteText: 'Êtes-vous sûr de vouloir supprimer ce tronçon ?',
          },
        },
        ConvoyingStep: {
          ToastMessage: {
            Success: {
              addLocomotive: 'Locomotive ajoutée avec succès',
              addAgent: 'Agent de conduite ajouté avec succès',
              addStep: 'Étape de convoyage ajoutée avec succès',
              deleteStep: 'Étape de convoyage supprimée avec succès',
              updateLocomotive: 'Locomotive modifiée avec succès',
              updateAgent: 'Agent de conduite modifié avec succès',
              updateStep: 'Étape de convoyage modifiée avec succès',
              deleteLocomotive: 'Locomotive supprimée avec succès',
              deleteAgent: 'Agent de conduite supprimé avec succès',
            },
          },
          title: (plural: boolean) => `Étape${plural ? 's' : ''} de convoyage`,
          step: 'Étape',
          footHight: 'Haut le pied',
          distance: 'Distance parcourue (km)',
          disconnectionDirection: 'Sens de débranchement',
          missionType: 'Type de mission',
          composition: 'Indice de composition',
          cco: 'CCO',
          gesco: 'GESCO',
          engineButton: 'EM & ADC',
          driveEngines: 'Engins moteurs',
          locomotive: 'Locomotive',
          driverAgents: 'Agents de conduite',
          driverAgent: 'Agent',
          addLocomotive: 'Ajouter une locomotive',
          addAgent: 'Ajouter un agent',
          codeEm: 'Code série EM',
          position: 'Position',
          ccoAssist: 'CCO d\'assistance',
          entityAssist: 'Entitée d\'assistance',
          codeTypeAgent: 'Code type agent',
          Modal: {
            deleteText: 'Êtes-vous sûr de vouloir supprimer cette étape de convoyage ?',
          },
        },
        Errors: {
          missionLength: 'La mission doit comporter exactement 6 caractères (chiffres et majuscules)',
          egs: 'Ce champ est obligatoire, veuillez renseigner une valeur',
          numSillon: 'Le numéro de sillon doit être un nombre et comporter moins de 10 chiffres',
        },
      },
    },
    Fluxs: {
      FilterModal: {
        title: 'Filtres',
        clients: 'Clients',
        search: 'Recherche',
        searchPlaceholder: 'Clé flux, origine du flux, destination du flux...',
        clientsPlaceholder: 'Sélectionnez un ou plusieurs client(s)',
      },
      Table: {
        Header: {
          status: 'Statut',
          flux: 'Flux',
          client: 'Client',
          sarNumber: 'Nombre de SAR',
          state: 'État',
          origin: 'Origine du flux',
          destination: 'Destination du flux',
        },
        SAR: {
          sar: 'SAR',
          length: 'Longueur',
          weight: 'Tonnage',
          lotsNumber: 'Nombre de lots',
          origin: 'Origine de la SAR',
          destination: 'Destination de la SAR',
          regime: 'Régime',
        },
      },
      Header: {
        client: 'Client',
      },
      charged: (charged: boolean) => `${charged ? 'Chargé' : 'Vide'}`,
    },
    Flux: {
      Import: {
        infoNoReimport: 'La SAR ne pourra pas être importée de nouveau ultérieurement.',
        sarTitle: 'Sélectionner la ou les SAR à importer dans RUS',
        loading: 'Import en cours',
        success: 'Les SAR suivantes ont bien été correctement importées :',
        failure: (successCount: number) => `${successCount} SAR importée !`,
        alreadyImported: 'déjà importée avec succès',
      },
      title: 'Flux',
      loaded: 'Chargé',
      'not-loaded': 'Vide',
      loadedAndNotLoaded: 'Chargé et vide',
      empty: 'Vide',
      updateSuccess: 'Flux modifié avec succès',
      modalAddClient: 'Ajouter un client',
      form: {
        title: 'Informations générales du flux',
        modifyKeyWarning: 'Attention : modifier la clé flux peut entraîner un dysfonctionnement de la fonctionnalité d’import vers RUS.\nSi vous modifiez la clé flux, veuillez reporter également la modification dans RUS, champ “Fiche flux ingénierie”.',
        key: 'Clé flux',
        client: 'Client',
        origin: 'Origine du flux',
        destination: 'Destination du flux',
        state: 'État',
        addClientSuccess: 'Client ajouté avec succès',
        addClient: 'Ajouter un client',
      },
      Sar: {
        title: 'SAR',
        add: 'Ajouter une SAR',
        sarInfoTitle: (sarTitle: string) => `Informations générales de la SAR ${sarTitle}`,
        circulationRegimeTitle: 'Régime de circulation',
        form: {
          key: 'Clé flux',
          state: 'État',
          sarOrigin: 'Origine de la SAR',
          sarDestination: 'Destination de la SAR',
          length: 'Longueur (mètres)',
          mass: 'Masse (tonnes)',
        },
        ToastMessage: {
          createSuccess: 'SAR ajoutée avec succès',
          updateSuccess: 'SAR modifiée avec succès',
          deleteSuccess: 'SAR supprimée avec succès',
        },
        Modal: {
          deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette SAR ?',
        },
        alertOrigin: 'L\'origine de la SAR est différente de l\'origine du flux',
        alertDestination: 'La destination de la SAR est différente de la destination du flux',
      },
      Lot: {
        title: 'Lot',
        add: 'Ajouter un lot',
        sarInfoTitle: 'Information générales de la SAR',
        lotInfoTitle: 'Information du Lot',
        updateSuccess: 'Lot modifié avec succès',
        ToastMessage: {
          createSuccess: 'Lot ajouté avec succès',
          deleteSuccess: 'Lot supprimé avec succès',
        },
        Modal: {
          deleteMessage: 'Êtes-vous sûr de vouloir supprimer ce LOT ?',
        },
        form: {
          length: 'Longeur (mètres)',
          mass: 'Masse (tonnes)',
          circulationRegime: 'Régime de circulation',
          routingOffset: 'Décalage acheminement lot',
        },
        Trame: {
          title: 'Trame des tronçons de mission associés au lot',
          updateSuccess: 'Trame modifiée avec succès',
          postSuccess: 'Trame ajoutée avec succès',
          deleteSuccess: 'Trame supprimée avec succès',
          modalDelete: 'Voulez-vous vraiment supprimer le tronçon de mission associé au lot ?',
          alertMessage: 'L’origine et la destination de deux tronçons de missions qui se suivent ne correspondent pas.',
          Form: {
            mission: 'Mission',
            origineDestination: 'Origine - destination du tonçon',
            jourCirculation: 'Jour de circulation',
          },
          addSection: 'Ajouter un tronçon',
        },
      },
      Delete: {
        Alert: {
          title: 'Voulez-vous vraiment supprimer le flux ?',
        },
        deleteSuccess: 'Le flux a bien été supprimé.',
      },
    },
  },
  Modals: {
    Filters: {
      title: 'Choix des filtres',
    },
  },
  Messages: {
    success: {
    },
    errors: {
    },
  },
} as const

export default terms
