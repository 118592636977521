import { ReactElement } from 'react'
import { ReactComponent as WeightIcon } from 'assets/icons/weight.svg'
import { ReactComponent as SizeIcon } from 'assets/icons/size.svg'
import { ReactComponent as BurgerIcon } from 'assets/icons/burger.svg'
import { ReactComponent as TrainIcon } from 'assets/icons/train.svg'
import { Lot } from 'types'

interface Props {
  lot: Lot
}

export function SectionLot({ lot }: Props): ReactElement {
  return (
    <div className={`lot${lot.isEtude ? ' study' : ''}`} key={lot.id}>
      <div className="title flex-center">
        <h4>{lot.libelle}</h4>
        <BurgerIcon />
      </div>
      <div className="details flex-center">
        <div>
          <WeightIcon />
          {`${lot.poids}t`}
        </div>
        <div>
          <SizeIcon />
          {`${lot.longueur}m`}
        </div>
        <div>
          <TrainIcon />
          {`${lot.nombreWagons}wg`}
        </div>
      </div>
    </div>
  )
}
