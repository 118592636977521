import { get, post } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import terms from 'assets/terms'
import { ToastLevel, ToastSignal } from 'components'
import { MatrixSignal } from 'services'
import { Lot, StudyLotType } from 'types'
import { catchable, weeklyDays } from 'utils'

//* --------------- List API URL study lot ---------------
export const studyLotApi = {
  STUDY_LOTS: (id: string) => `/apt/troncons/${id}/lot-etudes`,
  STUDY_LOT: (sectionId: string, id: string) => `/apt/troncons/${sectionId}/lot-etudes/${id}`,
}

//* --------------- Signal to store study lot ---------------
export const StudyLotsSignal = signal<StudyLotType[]>([])
export const StudyLotSignal = signal<StudyLotType>(null)

//* --------------- Update study lot to the signal ---------------
/**
 * Update study lot to the signal
 * @param studyLot - study lot
 */
export const updateStudyLot = (studyLot: StudyLotType) => {
  StudyLotSignal.value = studyLot
}

/**
 * Update study lots to the signal
 * @param studyLots - list of study lots
 */
export const updateStudyLots = (studyLots: StudyLotType[]) => {
  StudyLotsSignal.value = studyLots
}

//* --------------- API study lot ---------------

/**
 * Fetches all study lots for a section
 * @param id: string  Section id
*/
export const getStudyLots = (id: string) => catchable(async () => {
  const response = await get<StudyLotType[]>(studyLotApi.STUDY_LOTS(id))
  updateStudyLots(response)
})

/**
 * Fetches a study lot
 * @param sectionId: string  Section id
 * @param id: string  Study lot id
*/
export const getStudyLot = (sectionId: string, id: string) => catchable(async () => {
  const response = await get<StudyLotType>(studyLotApi.STUDY_LOT(sectionId, id))
  updateStudyLot(response)
})

/**
 * Create a study lot
 * @param sectionId: string  Section id
 * @param studyLot: StudyLotType  Study lot
*/
export const createStudyLot = (sectionId: string, studyLot: Partial<StudyLotType>) => catchable(async () => {
  const response = await post<StudyLotType>(studyLotApi.STUDY_LOTS(sectionId), studyLot)

  if (response) {
    MatrixSignal.value = {
      ...MatrixSignal.value,
      results: MatrixSignal.value.results.map(matrix => {
        if (matrix.tronconId === sectionId) {
          matrix.matrice = matrix.matrice.map(matrice => {
            if (response.joursCirculation.includes(weeklyDays[matrice.jour - 1])) {
              return {
                ...matrice,
                lots: [
                  ...matrice.lots,
                  {
                    id: response.id,
                    libelle: response.libelle,
                    poids: response.poids,
                    longueur: response.longueur,
                    nombreWagons: response.nombreWagons,
                    isEtude: true,
                  } as Lot,
                ],
              }
            }
            return matrice
          })
        }
        return matrix
      }),
    }
    ToastSignal.value = {
      message: terms.Pages.Missions.StudyLot.ToastMessage.createSuccess,
      severity: ToastLevel.SUCCESS,
    }
  }
}, true)
