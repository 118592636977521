import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import terms from 'assets/terms'
import { AddLotMissionModal, Button, ModalSignal } from 'components'
import { Link } from 'react-router-dom'
import { useRoles } from 'services'
import { Matrix, Paths } from 'types'
import { getStatusColor, saveToSession } from 'utils'
import { LotViewModeSignal } from '../MissionsPage'
import SectionCard from '../card/SectionCard'

import './MissionSection.scss'

interface Props {
  matrix: Matrix
}

export default function MissionsSection({ matrix }: Props) {
  const { isCat } = useRoles()

  const handleClickLink = () => {
    saveToSession('missionId', matrix.id)
  }

  const handleOpenAddLot = () => {
    ModalSignal.value = (
      <AddLotMissionModal
        subTitle={
          // eslint-disable-next-line max-len
          `${terms.Pages.Missions.mission} ${matrix.missionId} ${terms.Pages.Mission.Form.Section.section(false)} ${matrix.rangTroncon}/${matrix.nombreTroncons} ${matrix.origineTroncon}-${matrix.destinationTroncon}`
        }
        sectionId={matrix.tronconId}
      />
    )
  }

  return (
    <div
      className="mission-section"
      style={{ backgroundColor: getStatusColor(matrix.statutExport).background }}
    >
      <div className="header">
        <span style={{ backgroundColor: getStatusColor(matrix.statutExport).chip }} />
        <div className="title">
          <p>
            <span>
              {terms.Pages.Missions.mission}
            </span>
            <span>
              {matrix?.missionId}
            </span>
          </p>
          <p>
            <span>
              {terms.Pages.Missions.section.section}
            </span>
            <span>
              {`${matrix.rangTroncon}/${matrix.nombreTroncons}`}
            </span>
          </p>
          <p>
            {`${matrix.origineTroncon}-${matrix.destinationTroncon}`}
          </p>
        </div>
        {
          isCat && (
            <Link
              to={`${Paths.MISSIONS}/${matrix.missionId}`}
              onClick={handleClickLink}
            >
              <EditIcon />
            </Link>
          )
        }
        {
          LotViewModeSignal.value && (
            <Button
              text={terms.Pages.Missions.addGroup}
              icon={<PlusIcon />}
              onClick={handleOpenAddLot}
            />
          )
        }
      </div>
      <div className="sections">
        {matrix.matrice.map(day => (
          <SectionCard
            key={`${matrix.id}-${matrix.id}-${day?.jour}`}
            day={day}
          />
        ))}
      </div>
    </div>
  )
}
